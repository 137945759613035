<template>
  <v-row class="pa-0">
    <v-col class="px-0 col-12 pa-0">
      <span class="white--text headline pb-9">3. Lae pilet üles</span>
      <v-row class="flex-nowrap mt-4 pa-0 pt-10 w-100">
        <v-col class="pa-0">
          <file-select v-model="files" />
        </v-col>
      </v-row>
    </v-col>
    <v-col class="col-12 d-flex flex-wrap flex-column pa-0 mt-10">
      <NextBackButtons
        :first-button-disabled="false"
        :second-button-disabled="!userAddedTickets.length"
        @firstButtonOnClick="setStepperStep(2)"
        @secondButtonOnClick="setStepperStep(4)"
      >
        <template v-slot:firstButton>
          <v-icon
            left
            size="20"
            class="ma-0"
            style="position:absolute; left:0px"
            >mdi-chevron-left</v-icon
          >
          {{ $vuetify.lang.t('$vuetify.general.back') }}
        </template>
        <template v-slot:secondButton>
          {{ $vuetify.lang.t('$vuetify.general.next') }}
          <v-icon
            left
            size="20"
            class="ma-0"
            style="position:absolute; right:0px"
          >
            mdi-chevron-right
          </v-icon>
        </template>
      </NextBackButtons>
      <!-- <NextBackButtons :next-button-disabled="false" /> -->
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ThirdStep',
  components: {
    FileSelect: () =>
      import(/* webpackChunkName: "FileSelect" */ '@/components/FileSelect'),
    NextBackButtons: () =>
      import(
        /* webpackChunkName: "NextBackButtons" */ '@/components/AddingTicket/NextBackButtons'
      )
  },
  data() {
    return {
      files: [],
      ticketSalesIdRules: [
        v => v.length > 0 || 'Pileti ID on nõutud',
        v => v.length > 5 || 'Sisesta õige ID'
      ],
      ticketTypeRules: [
        v => v.length > 0 || 'Pileti tüüp on nõutud',
        v => v.length >= 3 || 'Täpsusta veel'
      ],
      ticketSeatRules: [
        v => v.length >= 1 || v.length === 0 || 'Istekoha info on nõutud',
        v => v.length <= 21 || v.length === 0 || 'Kirjeldus on liiga pikk'
      ],
      ticketPriceRules: [
        v => v.length >= 1 || 'Hind on nõutud',
        // eslint-disable-next-line no-restricted-globals
        v => !isNaN(Number(v)) || 'Sisesta number'
      ],
      openedPanel: [0],
      ticket: {
        index: 1,
        ticketType: '',
        seatDetails: '',
        salesId: '',
        price: Number(0)
      }
    }
  },
  computed: {
    ...mapGetters('events', ['stepperStep', 'userAddedTickets'])
  },
  // watch: {
  //   async files(newValue) {
  //     const formData = new FormData()
  //     formData.append(newValue[0].name, newValue[0])
  //     this.$store.dispatch('events/uploadFile', formData)
  //   }
  // },
  methods: {
    setStepperStep(step) {
      this.$store.commit('events/setStepperStep', step)
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0 0px !important;
  }
}
::v-deep .price-w-arrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    text-align: center;
    -moz-appearance: textfield;
  }
}
::v-deep .checkbox-field-theme {
  i {
    color: #ffffff;
  }
  .v-label {
    color: #ffffff !important;
  }
}
</style>
