<template>
  <div>
    <v-checkbox
      v-model="sellAsPatch"
      :style="{
        visibility: userAddedTickets.length >= 2 ? 'visible' : 'hidden'
      }"
      label="Müün piletid komplektina"
      class="checkbox-field-theme mt-0"
      color="primary"
    />
    <v-row v-if="!sellAsPatch">
      <v-col
        v-for="(userAddedTicket, i) in userAddedTickets"
        :key="userAddedTicket.id"
        cols="12"
        sm="6"
        md="6"
        class="pl-0"
      >
        <v-card class="ticket-card">
          <v-card-title primary-title class="white--text py-2">
            {{ `Pilet ${i + 1}:` }}
            <v-flex d-flex justify-end class="pr-0">
              <v-btn
                icon
                small
                color="primary"
                @click.stop="removeUserAddedTicket(userAddedTicket.id)"
              >
                <v-icon color="error" small>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-flex>
          </v-card-title>
          <v-card-text class="white--text py-0">
            <span class="d-block">{{ userAddedTicket.filename }}</span>
            <span>ID: {{ userAddedTicket.salesId }}</span>
          </v-card-text>
          <v-card-text class="pt-0">
            <v-text-field
              :value="userAddedTicket.ticketType"
              solo
              label="Pileti tüüp"
              class="text-fields-theme pt-9"
              required
              :rules="ticketTypeRules"
              @input="updateUserTicketType($event, userAddedTicket)"
            ></v-text-field>
            <v-checkbox
              :value="userAddedTicket.seatSelected"
              label="Määratud istekoht"
              class="checkbox-field-theme mt-0"
              color="primary"
              @change="setSeatSelected($event, userAddedTicket)"
            />
            <v-text-field
              v-if="userAddedTicket.seatSelected"
              :value="userAddedTicket.seatDetails"
              solo
              label="Istekoht"
              append-icon="mdi-seat"
              class="text-fields-theme"
              :rules="ticketSeatRules"
              @input="updateUserTicketSeat($event, userAddedTicket)"
            ></v-text-field>
            <div
              v-if="!sellAsPatch"
              class="d-flex text-center align-center justify-center"
            >
              <!-- <span class="white--text headline pb-9">
                    Määra hind
                  </span> -->
              <v-btn
                icon
                color="white"
                class="mr-2"
                @click="
                  userAddedTickets[i].price > 0
                    ? updateUserTicketPrice(
                        Number(userAddedTickets[i].price) - 1,
                        userAddedTicket
                      )
                    : ''
                "
              >
                <v-icon large>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
              <v-text-field
                id="id"
                solo
                name="name"
                label="hind"
                hide-details
                type="number"
                :value="
                  userAddedTickets[i].price ? userAddedTickets[i].price : 0
                "
                min="0"
                class="text-fields-theme price-w-arrows"
                style="max-width:80px;"
                required
                append-icon="mdi-currency-eur"
                @input="updateUserTicketPrice($event, userAddedTicket)"
              ></v-text-field>
              <v-btn
                icon
                color="white"
                class="ml-2"
                @click="
                  updateUserTicketPrice(
                    Number(userAddedTickets[i].price) + 1,
                    userAddedTicket
                  )
                "
              >
                <v-icon large>
                  mdi-chevron-up
                </v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="sellAsPatch" class="main-border-radius">
      <v-col
        v-for="(userAddedTicket, i) in userAddedTickets"
        :key="userAddedTicket.id"
        cols="12"
        sm="6"
        md="6"
        class="ticket-card patch"
      >
        <v-card class="ticket-card patch elevation-0 transparent">
          <v-card-title primary-title class="white--text py-2">
            {{ `Pilet ${i + 1}:` }}
            <v-flex d-flex justify-end class="pr-0">
              <v-btn
                icon
                small
                color="primary"
                @click.stop="removeUserAddedTicket(userAddedTicket.id)"
              >
                <v-icon color="error" small>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-flex>
          </v-card-title>
          <v-card-text class="white--text py-0">
            <span class="d-block">{{ userAddedTicket.filename }}</span>
            <span>ID: {{ userAddedTicket.salesId }}</span>
          </v-card-text>
          <v-card-text class="pt-0">
            <v-text-field
              :value="userAddedTicket.ticketType"
              solo
              label="Pileti tüüp"
              class="text-fields-theme pt-9"
              required
              :rules="ticketTypeRules"
              @input="updateUserTicketType($event, userAddedTicket)"
            ></v-text-field>
            <v-checkbox
              :value="userAddedTicket.seatSelected"
              label="Määratud istekoht"
              class="checkbox-field-theme mt-0"
              color="primary"
              @change="setSeatSelected($event, userAddedTicket)"
            />
            <v-text-field
              v-if="userAddedTicket.seatSelected"
              :value="userAddedTicket.seatDetails"
              solo
              label="Istekoht"
              append-icon="mdi-seat"
              class="text-fields-theme"
              :rules="ticketSeatRules"
              @input="updateUserTicketSeat($event, userAddedTicket)"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-card-actions class="px-3 w-100 ticket-card mt-2">
        <span class="white--text title">Komplekti hind</span>
        <v-spacer></v-spacer>
        <div class="d-flex align-center justify-center">
          <v-btn
            icon
            color="white"
            class="mr-2"
            @click="selectedPrice > 0 ? (selectedPrice -= 1) : ''"
          >
            <v-icon large>
              mdi-chevron-down
            </v-icon>
          </v-btn>
          <v-text-field
            id="id"
            v-model="selectedPrice"
            solo
            name="name"
            label="hind"
            hide-details
            type="number"
            :value="selectedPrice"
            min="0"
            class="text-fields-theme price-w-arrows"
            style="max-width: 80px;"
            append-icon="mdi-currency-eur"
            @input="updateUserTicketPrice(i, $event)"
          ></v-text-field>
          <!-- <v-text-field
            id="id"
            solo
            name="name"
            label="hind"
            hide-details
            type="number"
            :value="userAddedTicket.price ? userAddedTicket.price : 0"
            height="60"
            min="0"
            class="text-fields-theme price-w-arrows"
            style="max-width:80px;"
            required
            append-icon="mdi-currency-eur"
            @input="updateUserTicketPrice(i, $event)"
          ></v-text-field> -->
          <v-btn icon color="white" class="ml-2" @click="selectedPrice++">
            <v-icon large>
              mdi-chevron-up
            </v-icon>
          </v-btn>
        </div>
      </v-card-actions>
    </v-row>
    <div class="d-flex align-center flex-wrap">
      <v-checkbox
        v-model="allowSwap"
        color="primary"
        label="Soovid ka vahetusi?"
        class="checkbox-field-theme text-center"
      ></v-checkbox>
      <v-slide-y-transition hide-on-leave>
        <v-textarea
          v-show="allowSwap"
          v-model="swapDetails"
          solo
          name="input-7-4"
          label="Täpsusta enda eelistusi"
          class="text-fields-theme w-100 mx-md-12"
          transition="slide-y-transition"
        ></v-textarea>
      </v-slide-y-transition>
    </div>
    <v-col class="col-12 d-flex flex-wrap flex-column pa-0 mt-10">
      <NextBackButtons
        :first-button-disabled="false"
        :second-button-disabled="!validInfo()"
        @firstButtonOnClick="setStepperStep(3)"
        @secondButtonOnClick="setStepperStep(5)"
      >
        <template v-slot:firstButton>
          <v-icon
            left
            size="20"
            class="ma-0"
            style="position:absolute; left:0px"
            >mdi-chevron-left</v-icon
          >
          {{ $vuetify.lang.t('$vuetify.general.back') }}
        </template>
        <template v-slot:secondButton>
          {{ $vuetify.lang.t('$vuetify.general.next') }}
          <v-icon
            left
            size="20"
            class="ma-0"
            style="position:absolute; right:0px"
          >
            mdi-chevron-right
          </v-icon>
        </template>
      </NextBackButtons>
      <!-- <NextBackButtons :next-button-disabled="!validInfo()" /> -->
      <!-- <NextBackButtons :next-button-disabled="false" /> -->
    </v-col>
    <!-- <v-card-actions>
      <v-btn
        v-if="!addedNewEventFromDialog"
        background-color="darkBlue"
        class="d-block white--text w-100 text-capitalize rounded-10"
        style="background-color: #015C89; height: 50px"
        text
        @click="setStepperStep(stepperStep - 1)"
      >
        <v-icon left size="20" class="ma-0" style="position:absolute; left:0px">
          mdi-chevron-left
        </v-icon>
        {{ $vuetify.lang.t('$vuetify.general.back') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!validInfo()"
        class="d-block mb-4 w-100 text-capitalize rounded-10"
        style=" height: 50px"
        color="primary"
        @click="validInfo() ? setStepperStep(5) : ''"
      >
        {{ $vuetify.lang.t('$vuetify.general.next') }}
        <v-icon
          left
          size="20"
          class="ma-0"
          style="position:absolute; right:0px"
        >
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </v-card-actions> -->
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'FourthStep',
  components: {
    NextBackButtons: () =>
      import(
        /* webpackChunkName: "NextBackButtons" */ '@/components/AddingTicket/NextBackButtons'
      )
  },
  data() {
    return {
      ticketSalesIdRules: [
        v => v.length > 0 || 'Pileti ID on nõutud',
        v => v.length > 5 || 'Sisesta õige ID'
      ],
      ticketTypeRules: [
        v => v.length > 0 || 'Pileti tüüp on nõutud',
        v => v.length >= 3 || 'Täpsusta veel'
      ],
      ticketSeatRules: [
        v => v.length >= 3 || v.length === 0 || 'Täpsusta kirjeldust veel',
        v => v.length <= 21 || v.length === 0 || 'Kirjeldus on liiga pikk'
      ],
      ticketPriceRules: [
        v => v.length >= 1 || 'Hind on nõutud',
        // eslint-disable-next-line no-restricted-globals
        v => !isNaN(Number(v)) || 'Sisesta number'
      ],
      openedPanel: [0],
      ticket: {
        index: 1,
        ticketType: '',
        seatDetails: '',
        salesId: '',
        price: Number(0)
      }
    }
  },
  computed: {
    ...mapGetters('events', [
      'addedNewEventFromDialog',
      'stepperStep',
      'userAddedTickets'
    ]),
    ticketSalesId: {
      get() {
        return this.$store.state.events.ticketSalesId
      },
      set(value) {
        this.$store.commit('events/setTicketSalesId', value)
      }
    },
    ticketType: {
      get() {
        return this.$store.state.events.ticketType
      },
      set(value) {
        this.$store.commit('events/setTicketType', value)
      }
    },
    swapDetails: {
      get() {
        return this.$store.state.events.swapDetails
      },
      set(value) {
        this.$store.commit('events/setSwapDetails', value)
      }
    },
    ticketSeat: {
      get() {
        return this.$store.state.events.ticketSeat
      },
      set(value) {
        this.$store.commit('events/setTicketSeat', value)
      }
    },
    selectedPrice: {
      get() {
        return this.$store.state.events.selectedPrice
      },
      set(value) {
        this.$store.commit('events/setSelectedPrice', value)
      }
    },
    allowSwap: {
      get() {
        return this.$store.state.events.allowSwap
      },
      set(value) {
        this.$store.commit('events/setAllowSwap', value)
      }
    },
    sellAsPatch: {
      get() {
        return this.$store.state.events.sellAsPatch
      },
      set(value) {
        this.$store.commit('events/setSellAsPatch', value)
      }
    }
  },
  methods: {
    ...mapMutations('events', ['setUserAddedTicket', 'removeUserAddedTicket']),
    updateUserTicketType(value, ticket) {
      this.setUserAddedTicket({
        key: 'ticketType',
        value,
        ticketId: ticket.id
      })
    },
    setSeatSelected(value, ticket) {
      this.setUserAddedTicket({
        key: 'seatSelected',
        value,
        ticketId: ticket.id
      })
    },
    updateUserTicketSeat(value, ticket) {
      this.setUserAddedTicket({
        key: 'seatDetails',
        value,
        ticketId: ticket.id
      })
    },
    updateUserTicketPrice(value, ticket) {
      this.setUserAddedTicket({
        key: 'price',
        value: Number(value),
        ticketId: ticket.id
      })
    },
    validInfo() {
      if (this.userAddedTickets.length === 1) {
        return (
          this.userAddedTickets[0].ticketType.length >= 3 &&
          this.userAddedTickets[0].salesId.length >= 5
        )
      }
      if (this.userAddedTickets.length >= 2) {
        const validTickets = []
        this.userAddedTickets.forEach(ticket => {
          if (
            ticket.ticketType.length >= 3 &&
            ((ticket.seatDetails.length > 0 &&
              ticket.seatDetails.length < 21) ||
              ticket.seatDetails.length === 0) &&
            ticket.salesId.length >= 5 &&
            ticket.price !== '' &&
            ticket.price >= 0 &&
            // eslint-disable-next-line no-restricted-globals
            !isNaN(Number(ticket.price)) &&
            this.sellAsPatch !== null
          ) {
            validTickets.push(ticket)
          }
        })
        return validTickets.length === this.userAddedTickets.length
      }
      return true
    },
    setStepperStep(step) {
      this.$store.commit('events/setStepperStep', step)
    }
    // pushNewTicketToUserAddedTicketsArray() {
    //   if (this.userAddedTickets.length === 0) {
    //     this.$store.commit('events/addNewTicketsToUserAddedTicketsArray', {
    //       index: 0,
    //       ticketType: this.ticketType,
    //       seatDetails: this.ticketSeat,
    //       ticketSalesId: this.ticketSalesId,
    //       price: Number(this.selectedPrice)
    //     })
    //     this.openedPanel = [1]
    //     this.$store.commit(
    //       'events/addNewTicketsToUserAddedTicketsArray',
    //       this.ticket
    //     )
    //   } else if (this.userAddedTickets.length >= 2) {
    //     this.openedPanel = [this.openedPanel[0] + 1]
    //     this.$store.commit('events/addNewTicketsToUserAddedTicketsArray', {
    //       index: this.openedPanel[0],
    //       ticketType: '',
    //       seatDetails: '',
    //       ticketSalesId: '',
    //       price: Number(0),
    //       sellAsPatch: this.sellAsPatch
    //     })
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0 0px !important;
  }
}
::v-deep .price-w-arrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    text-align: center;
    -moz-appearance: textfield;
  }
}
::v-deep .checkbox-field-theme {
  i {
    color: #ffffff;
  }
  .v-label {
    color: #ffffff !important;
  }
}
.ticket-card {
  background: #0175ae;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  box-shadow: inset 0px 4px 100px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.ticket-card.patch:nth-child(odd) {
  border-right: 1px dashed #ffffff;
  border-right-width: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ticket-card.patch:nth-child(even) {
  border-left: none !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ticket-card.patch:nth-child(3n) {
  margin-top: 8px;
}
@media screen and (max-width: 600px) {
  .ticket-card.patch:nth-child(odd) {
    border-bottom: 1px dashed #ffffff;
    border-right: none;
    border-bottom-width: 2px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
  .ticket-card.patch:nth-child(even) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
  }
  .ticket-card.patch:nth-child(3n) {
    margin-top: 8px;
  }
}
</style>
